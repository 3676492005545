@import '../../../../styles/mixins/layout-mixins';
@import '../../../../styles/mixins/font-mixins';
@import '../../../../styles/config/variables';
.categoryWrapper {
  width: 220px;
  @include laptop-bp() {
    width: 453px;
  }
  margin: 10px;

  &.largeItem {
    width: 420px;
  }
}
.categoryImage {
  width: 100%;
  aspect-ratio: 1 / 1;
  position: relative;
  &__image {
    object-position: center;
    object-fit: cover;
  }
}
.heading {
  @include heading5;
  letter-spacing: -0.015em;
  text-align: left;
  color: $tp-rebuild-grey;
  margin-top: 24px;
  margin-bottom: 16px;
  @include laptop-bp() {
    @include heading4;
    margin-top: 32px;
  }
}
.description {
  width: 100%;
  margin-bottom: 16px;
  & p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    @include subHeading5;
    text-align: left;
    color: #33333399;

    @include laptop-bp() {
      @include subHeading4;
      font-size: 16px;
      text-align: left;
    }
  }
}
.redirect {
  display: flex;
  align-items: center;
  @include subHeading4;
  font-size: 16px;
  text-align: left;
  cursor: pointer;
  @include laptop-bp() {
    font-size: 18px;
    line-height: 26px;
  }
  &__line {
    margin-left: 16px;
    width: 64px;
    height: 0px;

    border: 1px solid $tp-rebuild-grey;
  }
}
